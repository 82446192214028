export const SUPPORTED_LOCALES = {
  EN_US: 'en-us',
  EN_CA: 'en-ca',
  FR_CA: 'fr-ca',
  EN_AU: 'en-au',
  EN_NZ: 'en-nz',
  EN_GB: 'en-gb',
  // DE_EU: 'de-eu',
  EN_EU: 'en-eu',
  // ES_EU: 'es-eu',
  // FR_EU: 'fr-eu',
  // IT_EU: 'it-eu',
  // NL_EU: 'nl-eu',
} as const;

// update this list when more EMEA locales are added
export const EMEA_LOCALES: string[] = [
  // SUPPORTED_LOCALES.DE_EU,
  SUPPORTED_LOCALES.EN_EU,
  // SUPPORTED_LOCALES.ES_EU,
  // SUPPORTED_LOCALES.FR_EU,
  // SUPPORTED_LOCALES.IT_EU,
  // SUPPORTED_LOCALES.NL_EU,
  SUPPORTED_LOCALES.EN_GB,
];

export const isUSLocale = (locale?: string) => locale === SUPPORTED_LOCALES.EN_US;
export const isGBLocale = (locale?: string) => locale === SUPPORTED_LOCALES.EN_GB;
export const isCALocale = (locale?: string) => locale === SUPPORTED_LOCALES.EN_CA || locale === SUPPORTED_LOCALES.FR_CA;
export const isNZLocale = (locale?: string) => locale === SUPPORTED_LOCALES.EN_NZ;
export const isDefaultLocaleIgnoreCase = (locale?: string) => locale?.toLocaleLowerCase() === SUPPORTED_LOCALES.EN_US;

/**
 * We originally had uppercase locale and some services still require this (ex. Tile Services)
 * ex: en-CA, en-NZ
 *
 * This doesn't validate whether the locale is a supported locale
 */
export const originalLocaleFormat = (locale?: string) =>
  locale?.replace(/-(.*)/, (str) => str.toLocaleUpperCase()) ?? '';

/**
 * get site locale from original locale key
 * @param originalLocale
 * @returns
 */
export const siteLocaleFormat = (originalLocale?: string) =>
  originalLocale?.replace(/-(.*)/, (str) => str.toLocaleLowerCase()) ?? '';

/**
 * to translate locale to contentful locale prefix within internal name
 * @param locale
 */
export const getContentfulContentLocale = (locale: string): string => {
  if (locale.toLowerCase().endsWith('ca')) {
    return locale.replace(/-/gi, '').toUpperCase();
  }

  return locale.split('-')?.[1]?.toUpperCase() || '';
};

export const isEmeaLocale = (locale: string): boolean => {
  return EMEA_LOCALES.includes(locale);
};
